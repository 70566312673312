import { useParams, useNavigate, Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import NewsService from '../../services/news'
import { useState } from 'react'

function EditNews({ getNews }) {
	const { id } = useParams()
	const { news } = useSelector(state => state.news)
	const navigate = useNavigate()

	const filterNews = news.filter(item => item._id === id)

	const [isLoading, setIsloading] = useState(false)
	const [file, setFile] = useState(null)
	const [title, setTitle] = useState(filterNews[0].title)
	const [description, setDescription] = useState(filterNews[0].description)

	const handleFileChange = event => {
		setFile(event.target.files[0])
	}
	const handleTitleChange = event => {
		setTitle(event.target.value)
	}
	const handleDescriptionChange = event => {
		setDescription(event.target.value)
	}

	const handleSubmit = async slug => {
		const data = new FormData()
		data.append('file', file)
		data.append('title', title)
		data.append('description', description)
		setIsloading(true)
		try {
			const response = await NewsService.editNews(data, slug)
			alert(response.message)
			// Malumotlarni tozalash
			setFile(null)
			setTitle('')
			setDescription('')
			setIsloading(false)
			navigate('/admin/news')
			getNews()
		} catch (error) {
			alert(error.response.data)
			setIsloading(false)
		}
	}

	return (
		<>
			<div className='w-full bg-body-tertiary p-10'>
				<div className='container'>
					<Link to='/admin/news'>Orqaga</Link>
					<div className='input-group input-group-sm mb-3 mt-4'>
						<input type='file' onChange={handleFileChange} />
					</div>
					<div className='input-group mb-3'>
						<span className='input-group-text' id='inputGroup-sizing-default'>
							Tadbir nomi
						</span>
						<input
							type='text'
							className='form-control'
							value={title}
							onChange={handleTitleChange}
						/>
					</div>
					<div className='input-group'>
						<span className='input-group-text'>Tadbir haqida</span>
						<textarea
							className='form-control'
							value={description}
							onChange={handleDescriptionChange}
						/>
					</div>
					<button
						type='button'
						className='btn btn-primary mt-3'
						onClick={() => handleSubmit(id)}
					>
						{isLoading ? 'Loading..' : 'Yuborish'}
					</button>
				</div>
			</div>
		</>
	)
}

export default EditNews
