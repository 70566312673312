import { useState } from 'react'

function Login() {
	const [username, setUsername] = useState('')
	const [password, setPassword] = useState('')

	const onSubmit = () => {
		// Foydalanuvchi ma'lumotlarini tekshirish
		if (username === 'admin' && password === 'adminparol') {
			// Foydalanuvchi ma'lumotlarini saqlash
			localStorage.setItem('user', JSON.stringify({ username, isAdmin: true }))
			window.location.href = '/admin' // Agar kirish muvaffaqiyatli bo'lsa, admin panelga yo'naltiriladi
		} else {
			// Foydalanuvchi to'g'ri ma'lumotlarni kiritmagan
			alert("Noto'g'ri foydalanuvchi nomi yoki paroli.")
		}
	}

	return (
		<div className=' flex flex-col w-full min-h-screen justify-center items-center'>
			<h2>Kirish</h2> <br />
			<input
				type='text'
				placeholder='Username'
				className='w-75 p-2 border-solid border-black border-[1px] rounded-md'
				value={username}
				onChange={e => setUsername(e.target.value)}
			/>{' '}
			<br />
			<input
				type='password'
				placeholder='Password'
				className='w-75 p-2 border-solid border-black border-[1px] rounded-md'
				value={password}
				onChange={e => setPassword(e.target.value)}
			/>{' '}
			<br />
			<button className='btn btn-secondary' type='submit' onClick={onSubmit}>
				Kirish
			</button>
		</div>
	)
}

export default Login
