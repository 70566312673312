import React from 'react'
import './Courses.css'
import FaqAccordion from '../../components/FaqAccordion/FaqAccordion'
import { Card } from 'react-bootstrap'

const courses = [
	{
		id: 1,
		img: 'https://res.cloudinary.com/dj3epjudt/image/upload/v1712580407/kasbi-frontend/courses1.jpg',
		title: '1.Автомобил двигателларини ташхислаш ва таъмирлаш  30711401',
		description:
			'Lorem ipsum dolor sit amet consectetur adipisicing elit. Magnam odio eos distinctio ducimus! Modi quaerat dolores illo accusamus maxime cupiditate culpa minus animi repellat autem!',
	},
	{
		id: 2,
		img: 'https://res.cloudinary.com/dj3epjudt/image/upload/v1712580464/kasbi-frontend/courses2.jpg',
		title: '2.Рақамли ахборотларни қайта ишлаш устаси  30610101',
		description:
			'Lorem ipsum dolor sit amet consectetur adipisicing elit. Magnam odio eos distinctio ducimus! Modi quaerat dolores illo accusamus maxime cupiditate culpa minus animi repellat autem!',
	},
	{
		id: 3,
		img: 'https://res.cloudinary.com/dj3epjudt/image/upload/v1712580513/kasbi-frontend/courses3.jpg',
		title: '3.Тракторчи-машинист  30810102',
		description:
			'Lorem ipsum dolor sit amet consectetur adipisicing elit. Magnam odio eos distinctio ducimus! Modi quaerat dolores illo accusamus maxime cupiditate culpa minus animi repellat autem!',
	},
]
const courses2 = [
	{
		id: 1,
		img: 'https://res.cloudinary.com/dj3epjudt/image/upload/v1712580407/kasbi-frontend/courses1.jpg',
		title: '1.Avtomobil dvigatellarini tashxislash va ta’mirlash  30711401',
		description:
			'Lorem ipsum dolor sit amet consectetur adipisicing elit. Magnam odio eos distinctio ducimus! Modi quaerat dolores illo accusamus maxime cupiditate culpa minus animi repellat autem!',
	},
	{
		id: 2,
		img: 'https://res.cloudinary.com/dj3epjudt/image/upload/v1712580513/kasbi-frontend/courses3.jpg',
		title:
			'2.Qishloq xo‘jalik mahsulotlarini qayta ishlash va saqlash laboranti  30810701',
		description:
			'Lorem ipsum dolor sit amet consectetur adipisicing elit. Magnam odio eos distinctio ducimus! Modi quaerat dolores illo accusamus maxime cupiditate culpa minus animi repellat autem!',
	},
	{
		id: 3,
		img: 'https://res.cloudinary.com/dj3epjudt/image/upload/v1712580513/kasbi-frontend/courses3.jpg',
		title:
			'3.Oqava suv va suv ta’minoti tizimlaridan foydalanish va montaj qilish ustasi  30730501',
		description:
			'Lorem ipsum dolor sit amet consectetur adipisicing elit. Magnam odio eos distinctio ducimus! Modi quaerat dolores illo accusamus maxime cupiditate culpa minus animi repellat autem!',
	},
	{
		id: 4,
		img: 'https://res.cloudinary.com/dj3epjudt/image/upload/v1712580513/kasbi-frontend/courses3.jpg',
		title: '4.Payvandlovchi (elektrgazpayvandlash ishlari)  30711201',
		description:
			'Lorem ipsum dolor sit amet consectetur adipisicing elit. Magnam odio eos distinctio ducimus! Modi quaerat dolores illo accusamus maxime cupiditate culpa minus animi repellat autem!',
	},
	{
		id: 5,
		img: 'https://res.cloudinary.com/dj3epjudt/image/upload/v1712580464/kasbi-frontend/courses2.jpg',
		title: '5.Raqamli axborotlarni qayta ishlash ustasi  30610101',
		description:
			'Lorem ipsum dolor sit amet consectetur adipisicing elit. Magnam odio eos distinctio ducimus! Modi quaerat dolores illo accusamus maxime cupiditate culpa minus animi repellat autem!',
	},
	{
		id: 6,
		img: 'https://res.cloudinary.com/dj3epjudt/image/upload/v1712580798/kasbi-frontend/courses4.jpg',
		title: '6.Tikuvchi  30720707',
		description:
			'Lorem ipsum dolor sit amet consectetur adipisicing elit. Magnam odio eos distinctio ducimus! Modi quaerat dolores illo accusamus maxime cupiditate culpa minus animi repellat autem!',
	},
	{
		id: 7,
		img: 'https://res.cloudinary.com/dj3epjudt/image/upload/v1712580513/kasbi-frontend/courses3.jpg',
		title: '7.Traktorchi-mashinist  30810102',
		description:
			'Lorem ipsum dolor sit amet consectetur adipisicing elit. Magnam odio eos distinctio ducimus! Modi quaerat dolores illo accusamus maxime cupiditate culpa minus animi repellat autem!',
	},
	{
		id: 8,
		img: 'https://res.cloudinary.com/dj3epjudt/image/upload/v1712580823/kasbi-frontend/courses5.jpg',
		title: '7.Elektrik  30710502',
		description:
			'Lorem ipsum dolor sit amet consectetur adipisicing elit. Magnam odio eos distinctio ducimus! Modi quaerat dolores illo accusamus maxime cupiditate culpa minus animi repellat autem!',
	},
]

function Courses() {
	return (
		<div className='courses-page'>
			<header className='height-75'>
				<div className='container h-100 d-flex flex-column align-items-center justify-content-center text-light'>
					<h1 className='text-center fw-semibold'>Мавжуд касблар </h1>
					<p className='text-center w-75 mb-5'>
						Бизнинг муассада 3та касб мавжуд бўлиб, улар Автомобил
						двигателларини ташхислаш ва таъмирлаш Рақамли ахборотларни қайта
						ишлаш устаси Тракторчи-машинист
					</p>
				</div>
			</header>

			<div className='bg-body-tertiary py-5'>
				<div className='container'>
					<div className='row g-4'>
						{courses.map(course => (
							<div key={course.id} className='col-md-6 col-lg-4'>
								<Card className='h-100 shadow scale-hover-effect bg-dark text-light border-0'>
									<Card.Img
										variant='top'
										src={course.img}
										className='course__image'
									/>
									<Card.Body className='p-md-3 d-flex flex-column align-items-center'>
										<Card.Title className='fs-6 mb-2 text-start'>
											{course.title}
										</Card.Title>
									</Card.Body>
								</Card>
							</div>
						))}
					</div>
				</div>
			</div>

			<Card.Title className='fs-4 mb-2 text-center'>
				2024-2025 ҚАБУЛ ҚИЛИНАДИГАН КАСБЛАР
			</Card.Title>

			<div className='bg-body-tertiary py-3'>
				<div className='container'>
					<div className='row g-4'>
						{courses2.map(course => (
							<div key={course.id} className='col-md-6 col-lg-4'>
								<Card className='h-100 shadow scale-hover-effect bg-dark text-light border-0'>
									<Card.Img
										variant='top'
										src={course.img}
										className='course__image'
									/>
									<Card.Body className='p-md-3 d-flex flex-column align-items-center'>
										<Card.Title className='fs-6 mb-2 text-start'>
											{course.title}
										</Card.Title>
									</Card.Body>
								</Card>
							</div>
						))}
					</div>
				</div>
			</div>

			<div className='bg-dark text-light py-5'>
				<FaqAccordion />
			</div>
		</div>
	)
}

export default Courses
