import { Link } from 'react-router-dom'
import { Card } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import NewsService from '../../services/news'
import Loading from '../../components/Loading/Loading'

function VeiwNews({ getNews }) {
	const { news, isLoading } = useSelector(state => state.news)

	const deleteNews = async slug => {
		try {
			const response = await NewsService.deleteNews(slug)
			alert(response.message)
			getNews()
		} catch (error) {
			console.log(error)
		}
	}

	return (
		<div className='bg-body-tertiary py-5 w-full flex justify-center'>
			<div className='container'>
				<div className='row g-4 '>
					{isLoading && <Loading />}
					{!isLoading &&
						news.map(blog => (
							<div key={blog._id} className='col-md-6 col-lg-4'>
								<Card className='h-100 shadow scale-hover-effect bg-dark text-light border-0'>
									<Link to={`/admin/news/${blog._id}`}>
										<Card.Img
											variant='top'
											src={blog.image}
											className='h-[200px] object-cover'
										/>
										<Card.Body className='p-md-3 d-flex flex-column align-items-center'>
											<Card.Title className='fs-5 mb-2 text-white'>
												{blog.title}
											</Card.Title>
											{/* <Card.Text className='fs-10 '>
												{blog.description}
											</Card.Text> */}
										</Card.Body>
									</Link>
									<div className='p-2 flex gap-2'>
										<Link to={`/admin/news/edit/${blog._id}`}>
											<button type='button' className='btn btn-secondary'>
												Tahrirlash
											</button>
										</Link>
										<button
											type='button'
											className='btn btn-danger '
											onClick={() => deleteNews(blog._id)}
										>
											O'chirish
										</button>
									</div>
								</Card>
							</div>
						))}
				</div>
			</div>
		</div>
	)
}

export default VeiwNews
