import React, { useState } from 'react'
import './Contact.css'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Button from 'react-bootstrap/Button'
import ContactService from '../../services/contacts'

import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

function Contact() {
	const [formData, setFormData] = useState({
		name: '',
		surname: '',
		tel: '',
		address: '',
		message: '',
	})

	const handleChange = e => {
		const { name, value } = e.target
		setFormData(prevState => ({
			...prevState,
			[name]: value,
		}))
	}

	const handleSubmit = async e => {
		e.preventDefault()
		try {
			const response = await ContactService.createContacts(formData)
			toast.success(response)
			setFormData({
				name: '',
				surname: '',
				tel: '',
				address: '',
				message: '',
			})
		} catch (error) {
			toast.error(error.response.data)
		}
	}

	return (
		<div className='contact-page'>
			<ToastContainer />
			<header className='height-75'>
				<div className='container h-100 d-flex flex-column align-items-center justify-content-center text-light'>
					<h1 className='text-center fw-semibold'>БОҒЛАНИШ</h1>
					<p className='text-center w-75 mb-5'>
						Lorem ipsum, dolor sit amet consectetur adipisicing elit. Vitae
						pariatur qui quos aspernatur, voluptatem autem possimus esse quo
						consequatur omnis, soluta consectetur ullam ipsum cum!
					</p>
				</div>
			</header>

			<div className='container my-5 d-flex justify-content-center'>
				<Form id='contact-form' onSubmit={handleSubmit}>
					<Row className='mb-3'>
						<Col sm={12} md={6} className='mb-3 mb-md-0'>
							<Form.Label>Исмингиз</Form.Label>
							<Form.Control
								type='text'
								placeholder='Исмингиз'
								name='name'
								value={formData.name}
								onChange={handleChange}
							/>
						</Col>
						<Col sm={12} md={6}>
							<Form.Label>Фамилия</Form.Label>
							<Form.Control
								type='text'
								placeholder='Фамилия'
								name='surname'
								value={formData.surname}
								onChange={handleChange}
							/>
						</Col>
					</Row>

					<Form.Group className='mb-3'>
						<Form.Label>Тельфон рақам</Form.Label>
						<Form.Control
							type='tel'
							placeholder='Тельфон рақам'
							name='tel'
							value={formData.tel}
							onChange={handleChange}
						/>
					</Form.Group>

					<Form.Group className='mb-3'>
						<Form.Label>Манзил</Form.Label>
						<Form.Control
							type='text'
							placeholder='Манзил'
							name='address'
							value={formData.address}
							onChange={handleChange}
						/>
					</Form.Group>

					<Form.Group className='mb-3'>
						<Form.Label>Хабар</Form.Label>
						<Form.Control
							as='textarea'
							rows={3}
							placeholder='Хабар'
							name='message'
							value={formData.message}
							onChange={handleChange}
						/>
					</Form.Group>

					<Button variant='danger btn-lg' type='submit'>
						Юбориш
					</Button>
				</Form>
			</div>

			<div className='bg-dark text-light py-5 px-0'>
				<div className='container'>
					<h2 className='text-center mb-5'>Биз жойлашган жой</h2>
					<div className='row g-4'>
						<iframe
							title='Kasbi School Address'
							src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d397640.9838862782!2d65.10365968906251!3d38.865194599999995!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3f4ec1d7d638a32f%3A0x6c4b6e267480c9c2!2sKasbi%20tumani%20Kasb-hunar%20kollej!5e0!3m2!1suz!2s!4v1704872860875!5m2!1suz!2s'
							width='600'
							height='450'
							allowFullScreen=''
							loading='lazy'
							referrerPolicy='no-referrer-when-downgrade'
						/>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Contact
