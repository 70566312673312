import Footer from '../components/Footer/Footer'
import { Outlet } from 'react-router-dom'
import Header from '../components/Navbar/Navbar'

function Layout() {
	return (
		<>
			<Header />
			<Outlet />
			<Footer />
		</>
	)
}

export default Layout
