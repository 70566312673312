import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import NewsService from '../../services/news'

function CreateNews({ getNews }) {
	const navigate = useNavigate()
	const [isLoading, setIsloading] = useState(false)
	const [file, setFile] = useState(null)
	const [title, setTitle] = useState('')
	const [description, setDescription] = useState('')

	const handleFileChange = event => {
		setFile(event.target.files[0])
	}
	const handleTitleChange = event => {
		setTitle(event.target.value)
	}
	const handleDescriptionChange = event => {
		setDescription(event.target.value)
	}

	const handleSubmit = async () => {
		const data = new FormData()
		data.append('file', file)
		data.append('title', title)
		data.append('description', description)
		setIsloading(true)
		try {
			const response = await NewsService.createNews(data)
			alert(response)
			// Malumotlarni tozalash
			setFile(null)
			setTitle('')
			setDescription('')
			setIsloading(false)
			navigate('/admin/news')
			getNews()
		} catch (error) {
			alert(error.response.data)
			setIsloading(false)
		}
	}

	return (
		<div className='w-full bg-body-tertiary p-10'>
			<div className='container'>
				<div className='input-group input-group-sm mb-3'>
					<input type='file' onChange={handleFileChange} />
				</div>
				<div className='input-group mb-3'>
					<span className='input-group-text' id='inputGroup-sizing-default'>
						Tadbir nomi
					</span>
					<input
						type='text'
						className='form-control'
						value={title}
						onChange={handleTitleChange}
					/>
				</div>
				<div className='input-group'>
					<span className='input-group-text'>Tadbir haqida</span>
					<textarea
						className='form-control'
						value={description}
						onChange={handleDescriptionChange}
					/>
				</div>
				<button
					type='button'
					className='btn btn-primary mt-3'
					onClick={handleSubmit}
				>
					{isLoading ? 'Loading..' : 'Yuborish'}
				</button>
			</div>
		</div>
	)
}

export default CreateNews
