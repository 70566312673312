import './App.css'
import { Routes, Route } from 'react-router-dom'
import React, { useEffect } from 'react'
import Home from './pages/Home/Home'
import Courses from './pages/Courses/Courses'
import About from './pages/About/About'
import Blog from './pages/Blog/Blog'
import Contact from './pages/Contact/Contact'
import Admin from './admin/Admin'
import Layout from './layout/Layout'
import Leader from './pages/Leader/Leader'
import Table from './admin/table/Table'
import CreateNews from './admin/news/CreateNews'
import VeiwNews from './admin/news/VeiwNews'
import EditNews from './admin/news/EditNews'
import {
	getNewsFailure,
	getNewsStart,
	getNewsSuccess,
} from './app/features/newsSlice'
import NewsService from './services/news'
import { useDispatch } from 'react-redux'
import BlogDetail from './pages/Blog/BlogDetail'
import VeivNewsDetail from './admin/news/VeivNewsDetail'

function App() {
	const dispatch = useDispatch()
	const getNews = async () => {
		dispatch(getNewsStart())
		try {
			const response = await NewsService.getNews()
			dispatch(getNewsSuccess(response))
		} catch (error) {
			dispatch(getNewsFailure(error))
			console.log(error)
		}
	}
	useEffect(() => {
		getNews()
	}, [])

	return (
		<div>
			<Routes>
				<Route path='/' element={<Layout />}>
					<Route index element={<Home />} />
					<Route path='/courses' element={<Courses />} />
					<Route path='/leader' element={<Leader />} />
					<Route path='/about' element={<About />} />
					<Route path='/blog' element={<Blog />} />
					<Route path='/contact' element={<Contact />} />
				</Route>
				<Route path='/blog/:id' element={<BlogDetail />} />
				<Route path='/admin' element={<Admin />}>
					<Route index element={<Table />} />
					<Route
						path='/admin/news/create'
						element={<CreateNews getNews={getNews} />}
					/>
					<Route path='/admin/news' element={<VeiwNews getNews={getNews} />} />
					<Route path='/admin/news/:id' element={<VeivNewsDetail />} />
					<Route
						path='/admin/news/edit/:id'
						element={<EditNews getNews={getNews} />}
					/>
				</Route>
			</Routes>
		</div>
	)
}

export default App
