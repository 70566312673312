import React from 'react'
import './About.css'
import { Link } from 'react-router-dom'
import ChooseSection from '../../components/ChooseSection/ChooseSection'

const persons = [
	{
		id: 1,
		img: 'https://res.cloudinary.com/dj3epjudt/image/upload/v1712578681/kasbi-frontend/bg-course.jpg',
	},
	{
		id: 2,
		img: 'https://res.cloudinary.com/dj3epjudt/image/upload/v1712578681/kasbi-frontend/bg-course.jpg',
	},
	{
		id: 3,
		img: 'https://res.cloudinary.com/dj3epjudt/image/upload/v1712578681/kasbi-frontend/bg-course.jpg',
	},
]

function About() {
	return (
		<div className='about-page'>
			<header className='height-75'>
				<div className='container h-100 d-flex flex-column align-items-center justify-content-center text-light'>
					<h1 className='text-center fw-semibold'>
						Биз ҳақимизда нималарни биласиз?
					</h1>
					<p className='text-center w-75 mb-5'>
						Қашқадарё вилояти Касби туманида жойлашган Касби туман 2-сонли
						касб-ҳунар мактаби
					</p>
				</div>
			</header>

			<div className='container my-5'>
				<div className='row'>
					<div className='col-lg-6 d-flex flex-column justify-content-center mb-4 mb-lg-0'>
						<h2 className='mb-4 mb-lg-5'>Бизда таълим олинг</h2>
						<p>
							2-сонли касб ҳунар мактаби биноси 1974-йилда қурилган бўлиб,
							2020-йилда асос солинган. Бу мактабнинг майдони қарийб 10,6
							гектарни ташкил этади. 15та бинога эга мактабимиз 750та талабани
							ўз бағрига сиғдира олади. Мактабнинг ўқув майдони 3299,74 квм ва
							49та ўқув биносига эга.
						</p>

						<Link to='/contact'>
							<button
								type='button'
								className='btn btn-danger btn-lg mx-0 mx-sm-2 my-2 my-sm-0'
							>
								Боғланиш
							</button>
						</Link>
					</div>
					<div className='col-lg-6 d-flex justify-content-center'>
						<img
							src='https://res.cloudinary.com/dj3epjudt/image/upload/v1712578681/kasbi-frontend/bg-course.jpg'
							className='img-fluid w-75'
							alt='rasm'
						/>
					</div>
				</div>
			</div>

			<div className='bg-dark text-light py-5'>
				<ChooseSection />
			</div>

			<div className='bg-body-tertiary py-5'>
				<div className='container'>
					<h2 className='text-center mb-5'>Бизнинг иқтидорли талабаримиз</h2>
					<div className='row g-4'>
						{persons.map(person => (
							<div key={person.id} className='col-md-4'>
								<img src={person.img} className='img-fluid' alt='' />
							</div>
						))}
					</div>
				</div>
			</div>
		</div>
	)
}

export default About
