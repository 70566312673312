import './Home.css'
import { Link } from 'react-router-dom'
import ChooseSection from '../../components/ChooseSection/ChooseSection'
import FaqAccordion from '../../components/FaqAccordion/FaqAccordion'
import Loading from '../../components/Loading/Loading'
import { Card } from 'react-bootstrap'
import { useSelector } from 'react-redux'

function Home() {
	const { news, isLoading } = useSelector(state => state.news)

	// Berilgan soni qadar elementlarni olish uchun funktsiya
	function getSelectedItemsFromArray(array, count) {
		return array.slice(0, count)
	}
	const selectedItems = getSelectedItemsFromArray(news, 3)

	return (
		<div className='home-page'>
			<header className='h-100 min-vh-100 d-flex align-items-center text-light'>
				<div className='container d-flex flex-column align-items-center'>
					<h2 className='text-center'>Хуш келибсиз мактабимизга!</h2>
					<h1 className='text-center fw-semibold'>
						Касби туман 2-сон касб ҳунар мактаби
					</h1>
					<div className='d-flex flex-column flex-sm-row align-items-center'>
						<Link to='/courses'>
							<button
								type='button'
								className='btn btn-danger btn-lg mx-0 mx-sm-2 my-2 my-sm-0'
							>
								Мавжуд касблар
							</button>
						</Link>
						<Link to='/contact'>
							<button
								type='button'
								className='btn btn-outline-light btn-lg mx-0 mx-sm-2 my-2 my-sm-0'
							>
								Боғланиш
							</button>
						</Link>
					</div>
				</div>
			</header>

			<div className='py-5'>
				<ChooseSection />
			</div>

			<div className='py-5 bg-light'>
				<div className='container'>
					<div className='row d-flex align-items-center justify-content-around'>
						<div className='col-lg-5'>
							<h2 className='text-capitalize'>ҚАШҚАДАРЁ АХБОРОТИ</h2>
							<p>
								"ҚАШҚАДАРЁ АХБОРОТИ" Айни шу кунларда 9-синф битирувчиларини
								касб-хунарга йўналтириш борасида турли тарғибот тадбирлари
								ташкил этилмоқда
							</p>
							<Link to='https://t.me/qashqadaryo_trk'>
								<button
									type='button'
									className='btn btn-danger btn-lg mx-0 mx-sm-2 my-2 my-sm-0'
								>
									Канални кўриш
								</button>
							</Link>
						</div>
						<div className='col-lg-5 mt-5 mt-lg-0'>
							<video width='550' controls className='vedio'>
								<source
									src='https://res.cloudinary.com/dj3epjudt/video/upload/v1712579088/kasbi-frontend/vedio.mp4'
									type='video/mp4'
								/>
							</video>
						</div>
					</div>
				</div>
			</div>

			<div className='py-5'>
				<FaqAccordion />
			</div>

			<div className='blog-section text-light py-5'>
				<div className='container d-flex flex-column align-items-center'>
					<h2 className='text-center text-capitalize mb-5'>Yangiliklar</h2>
					<div className='row g-4'>
						{isLoading && <Loading />}
						{!isLoading &&
							selectedItems.map(blog => (
								<div key={blog._id} className='col-md-6 col-lg-4'>
									<Link to='/blog' className='text-decoration-none'>
										<Card className='h-100 shadow scale-hover-effect'>
											<Card.Img
												variant='top'
												src={blog.image}
												className='h-[200px] object-cover'
											/>
											<Card.Body className='p-md-3'>
												<Card.Title>{blog.title}</Card.Title>
												{/* <Card.Text>{blog.description}</Card.Text> */}
											</Card.Body>
										</Card>
									</Link>
								</div>
							))}
					</div>
					<Link to='/blog'>
						<button type='button' className='btn btn-danger btn-lg mt-5'>
							Kўпроқ
						</button>
					</Link>
				</div>
			</div>
		</div>
	)
}

export default Home
