import { Link, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Loading from '../../components/Loading/Loading'

function VeivNewsDetail() {
	const { news, isLoading } = useSelector(state => state.news)
	const { id } = useParams()
	const filterNews = news.filter(item => item._id === id)

	if (isLoading) {
		return <Loading />
	}

	if (filterNews.length === 0) {
		return <div>Post topilmadi!</div>
	}

	return (
		<div className='py-10'>
			<Link to={'/admin/news'} className='p-10'>
				Orqaga
			</Link>
			<div className='w-full p-4'>
				<img
					src={filterNews[0].image}
					alt=''
					className='h-[300px] object-cover'
				/>
			</div>
			<div className='col p-4'>
				<h3 className='mb-0'>{filterNews[0].title}</h3>
				<div className='mb-1'>{filterNews[0].date}</div>
				<p className='card-text mb-auto'>{filterNews[0].description}</p>
			</div>
			<Link to={'/admin/news'} className='w-full flex justify-center '>
				Boshqa yangiliklar
			</Link>
		</div>
	)
}

export default VeivNewsDetail
