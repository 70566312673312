import axios from './api'

const ContactService = {
	async getContacts() {
		const response = await axios.get('/message')
		return response.data
	},
	async createContacts(formData) {
		const response = await axios.post('/message/create', formData)
		return response.data
	},
}

export default ContactService
