import React from 'react';
import './FaqAccordion.css';
import Accordion from 'react-bootstrap/Accordion';

function FaqAccordion() {
  return (
    <div className='faq-section'>
        <div className='container d-flex flex-column align-items-center'>
            <h2 className='text-center text-capitalize mb-5'>Кўп сўраладиган саволлар</h2>
            <p className='text-center mb-5'>Бизнинг муассаса ҳақида умумий ва кўп сўраладиган саволлар билан танишинг.</p>
            <Accordion defaultActiveKey="" flush>
                <Accordion.Item eventKey='0'>
                    <Accordion.Header>Таълим муассасада нечта йўналиш турлари мавжуд ?</Accordion.Header>
                    <Accordion.Body>
                    Бизнинг таълимда 8та таълим йўналишлари мавжуд. 
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey='1'>
                    <Accordion.Header>Амалиёт фанлар мавжудми ?</Accordion.Header>
                    <Accordion.Body>
                    Албатта бизда амалиёт фанлари мавжуд бўлиб, амалиёт қилиш учун алоҳида устахона ва лаборатория хоналаримиз мавжуд
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey='2'>
                    <Accordion.Header>Ҳар йили тахминан неча нафар абитуриент қабул қилинади ?</Accordion.Header>
                    <Accordion.Body>
                    Бу йўналишларга ҳар йили 400дан ортиқ абитуриентлар қабул қилинади
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey='3'>
                    <Accordion.Header>Битиргандан сўнг ҳайдовчилик гувоҳномаси бериладими ?</Accordion.Header>
                    <Accordion.Body>
                        Lorem, ipsum dolor sit amet consectetur adipisicing elit. Molestiae ab deserunt eaque nostrum voluptate, facilis ratione quo ducimus quod qui eius nobis quam temporibus similique fugit? Molestias, illo, maxime nulla aliquid ut pariatur corrupti aspernatur dolor assumenda quos officia eos?
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey='4'>
                    <Accordion.Header>Битиргандан сўнг иш билан таминланадими?</Accordion.Header>
                    <Accordion.Body>
                    Албатта бизнинг мактабни битирган талабаримиз диплом билан тақдирланади ва бу диплом орқали мутахасислик орқали ишга жойланишлари мумкин
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </div>
    </div>
  )
}

export default FaqAccordion;