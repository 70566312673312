import React, { useEffect } from 'react'
import './Blog.css'
import { Link } from 'react-router-dom'
import { Card } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import Loading from '../../components/Loading/Loading'

const ScrollToTop = () => {
	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])
	return null
}

function Blog() {
	const { news, isLoading } = useSelector(state => state.news)

	return (
		<div className='blog-page'>
			<header className='height-75'>
				<div className='container h-100 d-flex flex-column align-items-center justify-content-center text-light'>
					<h1 className='text-center fw-semibold'>ЯНГИЛИКЛАР</h1>
					<p className='text-center w-75 mb-5'>
						Lorem ipsum dolor sit amet consectetur adipisicing elit. Mollitia
						voluptas suscipit ratione quod culpa, eius ad consequatur, dolor
						quasi nulla optio quo error tempora temporibus distinctio quidem
						asperiores dolore ex amet nam. Consequatur, odit corporis.
					</p>
				</div>
			</header>

			<div className='bg-body-tertiary py-5'>
				<ScrollToTop />
				<div className='container'>
					<div className='row g-4'>
						{isLoading && <Loading />}
						{!isLoading &&
							news.map(blog => (
								<div key={blog._id} className='col-md-6 col-lg-4'>
									<Link to={`/blog/${blog._id}`}>
										<Card className='h-100 shadow scale-hover-effect bg-dark text-light border-0'>
											<Card.Img
												variant='top'
												src={blog.image}
												className='h-[200px] object-cover'
											/>
											<Card.Body className='p-md-3 d-flex flex-column align-items-center'>
												<Card.Title className='fs-5 mb-2'>
													{blog.title}
												</Card.Title>
												{/* <Card.Text className='fs-10 '>
												{blog.description}
											</Card.Text> */}
											</Card.Body>
										</Card>
									</Link>
								</div>
							))}
					</div>
				</div>
			</div>
		</div>
	)
}

export default Blog
