import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	isLoading: false,
	news: [],
	error: null,
}

const newsSlice = createSlice({
	name: 'news',
	initialState,
	reducers: {
		getNewsStart: state => {
			state.isLoading = true
		},
		getNewsSuccess: (state, action) => {
			state.isLoading = false
			state.news = action.payload
		},
		getNewsFailure: (state, action) => {
			state.isLoading = false
			state.error = action.payload
		},
	},
})

export default newsSlice.reducer
export const { getNewsStart, getNewsSuccess, getNewsFailure } =
	newsSlice.actions
