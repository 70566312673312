import { useEffect, useState } from 'react'
import Login from './login/Login'
import SideBar from './sideBar/SideBar'
import { Outlet } from 'react-router-dom'

const Admin = () => {
	const [isLoggedIn, setIsLoggedIn] = useState(false)

	useEffect(() => {
		// Foydalanuvchi ma'lumotlarini olish
		const user = localStorage.getItem('user')
		if (user) {
			const { isAdmin } = JSON.parse(user)
			setIsLoggedIn(isAdmin)
		}
	}, [])
	return (
		<>
			{isLoggedIn ? (
				<div className='flex justify-center items-center '>
					<div className='flex w-full min-h-screen '>
						<SideBar />
						<Outlet />
					</div>
				</div>
			) : (
				<Login />
			)}
		</>
	)
}

export default Admin
