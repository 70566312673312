import './Leader.css'
import { Link } from 'react-router-dom'
import { Card } from 'react-bootstrap'

const liders = [
	{
		id: 1,
		img: 'https://res.cloudinary.com/dj3epjudt/image/upload/v1712579344/kasbi-frontend/director-img.jpg',
		fullName: 'Фозилов Бахром Рахмонович',
		job: 'Касби туман 2-сонли Касб-ҳунар мактабининг директори',
		phoneNumber: '93.690-84-62',
	},
	{
		id: 2,
		img: 'https://res.cloudinary.com/dj3epjudt/image/upload/v1712579387/kasbi-frontend/director-2-img.jpg',
		fullName: 'Нурматов Отабек Бозорович',
		job: 'Касби туман 2-сонли Касб-ҳунар мактабининг ўқув ишлари бўйича директор ўринбосари',
		phoneNumber: '99.053-07-71',
	},
	{
		id: 3,
		img: 'https://res.cloudinary.com/dj3epjudt/image/upload/v1712579449/kasbi-frontend/director-3-img.jpg',
		fullName: 'Икромов Бахтиёр Бахриддинович',
		job: 'Касби туман 2-сонли Касб-ҳунар мактабининг ишлаб чиқариш таълими бўйича директор ўринбосари',
		phoneNumber: '90.729-02-62',
	},
	{
		id: 4,
		img: 'https://res.cloudinary.com/dj3epjudt/image/upload/v1712579482/kasbi-frontend/director-4-img.jpg',
		fullName: 'Нафасов Хайдар Нахшванович',
		job: 'Касби туман 2-сонли Касб-ҳунар мактабининг ёшлар билан ишлаш бўйича директор ўринбосари',
		phoneNumber: '93.904-63-18',
	},
	{
		id: 5,
		img: 'https://res.cloudinary.com/dj3epjudt/image/upload/v1712579523/kasbi-frontend/director-6-img.jpg',
		fullName: 'Рўзимуродов Отабек Ашурмахмадович',
		job: 'Касби туман 2-сонли Касб-ҳунар мактабининг Бўлим бошлиғи',
		phoneNumber: '94.293-39-49',
	},
	{
		id: 6,
		img: 'https://res.cloudinary.com/dj3epjudt/image/upload/v1712579553/kasbi-frontend/director-7-img.jpg',
		fullName: 'Холов Анвар Абдуалиевич',
		job: 'Касби туман 2-сонли Касб-ҳунар мактабининг Катта устаси',
		phoneNumber: '91.220-46-58',
	},
	{
		id: 7,
		img: 'https://res.cloudinary.com/dj3epjudt/image/upload/v1712579582/kasbi-frontend/director-8-img.jpg',
		fullName: 'Поёнова Шохсанам Наримовна',
		job: 'Касби туман 2-сонли Касб-ҳунар мактабининг Табиий ва аниқ фанлар кафедраси мудири',
		phoneNumber: '90.288-97-43',
	},
	{
		id: 8,
		img: 'https://res.cloudinary.com/dj3epjudt/image/upload/v1712579610/kasbi-frontend/director-9-img.jpg',
		fullName: 'Нарзуллаев Ибрат Дилмуродович',
		job: 'Касби туман 2-сонли Касб-ҳунар мактабининг Муҳандислик ва қишлоқ хўжалиги  фанлари  кафедраси мудири',
		phoneNumber: '93.422-41-03',
	},
]

function Leader() {
	return (
		<div className='leader-page'>
			<header className='height-75'>
				<div className='container h-100 d-flex flex-column align-items-center justify-content-center text-light'>
					<h1 className='text-center fw-semibold'>PАҲБАРЛАР</h1>
					<p className='text-center w-75 mb-5'>
						Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas
						aliquam officia illum eveniet, dolor cupiditate quibusdam
						repudiandae, accusantium rem vitae laboriosam nemo blanditiis autem
						a quia natus voluptate exercitationem. Facere.
					</p>
				</div>
			</header>
			<div className='bg-body-tertiary py-5 px-2'>
				<div className='container'>
					<div className='row g-10'>
						{liders.map(lider => (
							<div key={lider.id} className='col-md-4 col-lg-3 col-sm-6'>
								<Link to='#' className='text-decoration-none'>
									<Card className='h-100 shadow scale-hover-effect bg-dark text-light border-0'>
										<Card.Img variant='top' src={lider.img} />
										<Card.Body className='p-md-3 d-flex flex-column'>
											<Card.Title className='fs-6 mb-2 fw-bold'>
												<p>
													Исм-фамилияси:{' '}
													<span className='fw-light'>{lider.fullName}</span>
												</p>
											</Card.Title>
											<Card.Text className='fs-6 fw-bold'>
												<p>
													Лавозими:{' '}
													<span className='fw-light'>{lider.job}</span>
												</p>
											</Card.Text>
											<Card.Text className='fs-6 fw-bold'>
												<p>
													Тел раками:{' '}
													<span className='fw-light'>{lider.phoneNumber}</span>
												</p>
											</Card.Text>
										</Card.Body>
									</Card>
								</Link>
							</div>
						))}
					</div>
				</div>
			</div>
		</div>
	)
}

export default Leader
