import axios from './api'

const NewsService = {
	async getNews() {
		const response = await axios.get('/news')
		return response.data
	},
	async deleteNews(slug) {
		const response = await axios.delete(`/news/${slug}`)
		return response.data
	},
	async createNews(data) {
		const response = await axios.post('/news/create', data)
		return response.data
	},
	async editNews(data, slug) {
		const response = await axios.put(`/news/${slug}`, data)
		return response.data
	},
}

export default NewsService
