import { Link } from 'react-router-dom'

function SideBar() {
	const handleLogout = () => {
		// LocalStorage dan foydalanuvchi ma'lumotlarini o'chirish
		localStorage.removeItem('user')
	}
	return (
		<div
			className='d-flex flex-column flex-shrink-0 p-3 text-bg-dark'
			style={{ width: 280 }}
		>
			<Link
				to='/admin'
				className='d-flex align-items-center mb-3 mb-md-0 me-md-auto text-white text-decoration-none'
			>
				<span className='fs-4'>Kasbi.2-son.khm.uz</span>
			</Link>

			<hr />
			<ul className='nav nav-pills flex-column mb-auto'>
				<li className='nav-item'>
					<Link to='/admin' className='nav-link text-white' aria-current='page'>
						Xabarlar
					</Link>
				</li>
				<li>
					<Link to='/admin/news/create' className='nav-link text-white'>
						Yangilik qo'shish
					</Link>
				</li>
				<li>
					<Link to='/admin/news' className='nav-link text-white'>
						Yangiliklar
					</Link>
				</li>
				<hr />
				<Link to='/' className='nav-link text-white' onClick={handleLogout}>
					Chiqish
				</Link>
			</ul>
			<hr />
		</div>
	)
}

export default SideBar
