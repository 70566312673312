import { useEffect, useState } from 'react'
import ContactService from '../../services/contacts'

function Table() {
	const [message, setMessage] = useState(null)
	const [error, setError] = useState(null)
	const [isLoading, setIsLoading] = useState(false)

	const getContacts = async () => {
		setIsLoading(true)
		try {
			const response = await ContactService.getContacts()
			setMessage(response)
			setIsLoading(false)
		} catch (error) {
			setError(error)
			setIsLoading(false)
		}
	}

	useEffect(() => {
		getContacts()
	}, [])

	return (
		<div className='w-full'>
			{isLoading ? (
				<div className='w-full flex justify-center items-center'>
					Loading...
				</div>
			) : (
				<div>
					{error !== null && <p className='text-center p-4'>{error.message}</p>}
					<div className='flex flex-col overflow-x-auto'>
						<div className='sm:-mx-6 lg:-mx-8'>
							<div className='inline-block min-w-full py-2 sm:px-6 lg:px-8'>
								<div className='overflow-x-auto'>
									<table className='min-w-full text-start text-sm font-light text-surface '>
										<thead className='border-b border-neutral-200 font-medium '>
											<tr>
												<th scope='col' className='px-6 py-4'>
													#
												</th>
												<th scope='col' className='px-6 py-4'>
													Исми
												</th>
												<th scope='col' className='px-6 py-4'>
													Фамилия
												</th>
												<th scope='col' className='px-6 py-4'>
													Тельфон рақам
												</th>
												<th scope='col' className='px-6 py-4'>
													Манзил
												</th>
												<th scope='col' className='px-6 py-4'>
													Хабар
												</th>
											</tr>
										</thead>
										<tbody>
											{message &&
												message.map(item => {
													return (
														<tr
															key={item._id}
															className='border-b border-neutral-300 '
														>
															<td className='border-solid border-black border-[1px] rounded-md px-4'>
																{item.id}
															</td>
															<td className='border-solid border-black border-[1px] rounded-md p-2'>
																{item.name}
															</td>
															<td className='border-solid border-black border-[1px] rounded-md p-2'>
																{item.surname}
															</td>
															<td className='border-solid border-black border-[1px] rounded-md p-2'>
																{item.tel}
															</td>
															<td className='border-solid border-black border-[1px] rounded-md p-2'>
																{item.address}
															</td>
															<td className='max-w-[400px] border-solid border-black border-[1px] rounded-md p-2'>
																{item.message}
															</td>
														</tr>
													)
												})}
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
		</div>
	)
}

export default Table
